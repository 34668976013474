import {
  createTRPCClient,
  httpBatchLink,
  httpLink,
  splitLink,
  loggerLink,
  type TRPCLink,
} from "@trpc/client";
import { observable } from "@trpc/server/observable";
import { jwtDecode } from "jwt-decode";

import type { AppRouter } from "@mono/api/src/server/trpc/router";

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  const { logout } = useAuthStore();
  const snack = useSnack();
  const API_URL =
    config.public.API_TARGET !== "staging"
      ? config.public.TRPC_URL
      : config.public.TRPC_URL.replace("http:", "https:").replace("80", "3030");

  const errorLink: TRPCLink<AppRouter> = () => {
    // here we just got initialized in the app - this happens once per app
    // useful for storing cache for instance
    return ({ next, op }) => {
      // this is when passing the result to the next link
      // each link needs to return an observable which propagates results
      return observable((observer) => {
        const unsubscribe = next(op).subscribe({
          next(value) {
            observer.next(value);
          },
          error(err) {
            observer.error(err);
            if (err?.data?.code === "UNAUTHORIZED") {
              snack.error("Authorization error!");
              logout();
            }
          },
          complete() {
            observer.complete();
          },
        });
        return unsubscribe;
      });
    };
  };

  const beforeRequestLink: TRPCLink<AppRouter> = () => {
    return ({ next, op }) => {
      const { authStorage } = useAuthStorage();

      const blacklistPaths = ["auth.loginUser"];

      if (
        authStorage.value.token != null &&
        blacklistPaths.includes(op.path) === false
      ) {
        // check if token is expiring
        const { exp: tokenExp } = jwtDecode<{ exp: number }>(
          authStorage.value.token!
        );

        const timeToExpire = Math.round(tokenExp - Date.now() / 1000);

        // if token is expiring in less than 60 seconds
        if (timeToExpire < 60) {
          logout();
        }
      }

      return next(op);
    };
  };

  const trpcClient = createTRPCClient<AppRouter>({
    links: [
      beforeRequestLink,
      errorLink,
      loggerLink({
        enabled: (opts) =>
          (process.env.NODE_ENV === "development" &&
            typeof window !== "undefined") ||
          (opts.direction === "down" && opts.result instanceof Error),
      }),
      splitLink({
        condition() {
          return true;
          // check for context property `skipBatch`
          // return Boolean(op.context.skipBatch);
        },
        // when condition is true, use normal request
        true: httpLink({
          url: API_URL,
          headers() {
            const { authStorage } = useAuthStorage();

            return {
              Authorization:
                authStorage.value.token != null
                  ? `Bearer ${authStorage.value.token}`
                  : undefined,
            };
          },
          fetch(url, options) {
            return fetch(url, options);
          },
        }),
        // when condition is false, use batching
        false: httpBatchLink({
          url: API_URL,
          maxURLLength: 2083,
          headers() {
            const { authStorage } = useAuthStorage();

            return {
              Authorization:
                authStorage.value.token != null
                  ? `Bearer ${authStorage.value.token}`
                  : undefined,
            };
          },
          fetch(url, options) {
            return fetch(url, options);
          },
        }),
      }),
    ],
  });

  return {
    provide: {
      trpcClient,
    },
  };
});
