import { default as indexkYyVcp4xldMeta } from "/vercel/path0/apps/admin/pages/auth/password/reset/index.vue?macro=true";
import { default as _91uuid_93k8NK7Py9sFMeta } from "/vercel/path0/apps/admin/pages/auth/password/reset/submit/[uuid].vue?macro=true";
import { default as verify_45_91otpId_93n7lBhV2lBaMeta } from "/vercel/path0/apps/admin/pages/auth/verify-[otpId].vue?macro=true";
import { default as indexGLD2GXsKzXMeta } from "/vercel/path0/apps/admin/pages/dashboard/admins/index.vue?macro=true";
import { default as indexf9Pm9Bm6xkMeta } from "/vercel/path0/apps/admin/pages/dashboard/affiliates/index.vue?macro=true";
import { default as assignedcount5mw7oEopSSMeta } from "/vercel/path0/apps/admin/pages/dashboard/crm/analytics/assignedcount.vue?macro=true";
import { default as assignedtimesqBZskakOduMeta } from "/vercel/path0/apps/admin/pages/dashboard/crm/analytics/assignedtimes.vue?macro=true";
import { default as closingtimesezC4hjr5zUMeta } from "/vercel/path0/apps/admin/pages/dashboard/crm/analytics/closingtimes.vue?macro=true";
import { default as indextw6mlrqH1WMeta } from "/vercel/path0/apps/admin/pages/dashboard/crm/analytics/index.vue?macro=true";
import { default as lostcount69okkKYdkFMeta } from "/vercel/path0/apps/admin/pages/dashboard/crm/analytics/lostcount.vue?macro=true";
import { default as woncountJ5H28BZmanMeta } from "/vercel/path0/apps/admin/pages/dashboard/crm/analytics/woncount.vue?macro=true";
import { default as analytics7Q0mcgQG2KMeta } from "/vercel/path0/apps/admin/pages/dashboard/crm/analytics.vue?macro=true";
import { default as indexKnLM0geN8TMeta } from "/vercel/path0/apps/admin/pages/dashboard/crm/index.vue?macro=true";
import { default as importZC4JIniZOYMeta } from "/vercel/path0/apps/admin/pages/dashboard/crm/manage/import.vue?macro=true";
import { default as industriespWb6PCODiwMeta } from "/vercel/path0/apps/admin/pages/dashboard/crm/manage/industries.vue?macro=true";
import { default as locationsfLiGdA7YmYMeta } from "/vercel/path0/apps/admin/pages/dashboard/crm/manage/locations.vue?macro=true";
import { default as sourcesA4Bu3vpfSUMeta } from "/vercel/path0/apps/admin/pages/dashboard/crm/manage/sources.vue?macro=true";
import { default as ticketsFTf6qjGh0oMeta } from "/vercel/path0/apps/admin/pages/dashboard/crm/manage/tickets.vue?macro=true";
import { default as managemOG1aaVuNSMeta } from "/vercel/path0/apps/admin/pages/dashboard/crm/manage.vue?macro=true";
import { default as claimoRlQjUGVMNMeta } from "/vercel/path0/apps/admin/pages/dashboard/crm/myleads/claim.vue?macro=true";
import { default as indexucB2hfRzJ8Meta } from "/vercel/path0/apps/admin/pages/dashboard/crm/myleads/index.vue?macro=true";
import { default as salesflowuaBox0SxcyMeta } from "/vercel/path0/apps/admin/pages/dashboard/crm/myleads/salesflow.vue?macro=true";
import { default as myleads48DnWNfp2rMeta } from "/vercel/path0/apps/admin/pages/dashboard/crm/myleads.vue?macro=true";
import { default as indexdFqijecgCtMeta } from "/vercel/path0/apps/admin/pages/dashboard/financial/index.vue?macro=true";
import { default as index8B1yqUqPilMeta } from "/vercel/path0/apps/admin/pages/dashboard/index.vue?macro=true";
import { default as _91orderId_939E65HCjyaWMeta } from "/vercel/path0/apps/admin/pages/dashboard/orders/[orderId].vue?macro=true";
import { default as indexhWEYgp7GcUMeta } from "/vercel/path0/apps/admin/pages/dashboard/orders/index.vue?macro=true";
import { default as addxpBBQM7D6nMeta } from "/vercel/path0/apps/admin/pages/dashboard/organizations/[orgId]/addons/add.vue?macro=true";
import { default as removeFHF3ensHELMeta } from "/vercel/path0/apps/admin/pages/dashboard/organizations/[orgId]/addons/remove.vue?macro=true";
import { default as indexg1AHg6gJImMeta } from "/vercel/path0/apps/admin/pages/dashboard/organizations/[orgId]/index.vue?macro=true";
import { default as addk0j72PbnCqMeta } from "/vercel/path0/apps/admin/pages/dashboard/organizations/[orgId]/subscription/add.vue?macro=true";
import { default as indexrFFK7hG797Meta } from "/vercel/path0/apps/admin/pages/dashboard/organizations/index.vue?macro=true";
import { default as syncaccessPexIAYVOGaMeta } from "/vercel/path0/apps/admin/pages/dashboard/organizations/syncaccess.vue?macro=true";
import { default as indexrp49EyA1U1Meta } from "/vercel/path0/apps/admin/pages/dashboard/plans/index.vue?macro=true";
import { default as _91id_93671RhuD0xaMeta } from "/vercel/path0/apps/admin/pages/dashboard/print/order/[id].vue?macro=true";
import { default as indexR6vqlqRhheMeta } from "/vercel/path0/apps/admin/pages/dashboard/promotions/index.vue?macro=true";
import { default as indexRtK6DzCIIZMeta } from "/vercel/path0/apps/admin/pages/dashboard/subscription-renewals/index.vue?macro=true";
import { default as _91userId_93Lihjb5weFIMeta } from "/vercel/path0/apps/admin/pages/dashboard/users/[userId].vue?macro=true";
import { default as indexoqM6PdO93CMeta } from "/vercel/path0/apps/admin/pages/dashboard/users/index.vue?macro=true";
import { default as index9a5tgJ4G0hMeta } from "/vercel/path0/apps/admin/pages/dashboard/zatca/index.vue?macro=true";
import { default as dashboardbE7pCL9f6LMeta } from "/vercel/path0/apps/admin/pages/dashboard.vue?macro=true";
import { default as index1xexOXsc4pMeta } from "/vercel/path0/apps/admin/pages/index.vue?macro=true";
export default [
  {
    name: "auth-password-reset",
    path: "/auth/password/reset",
    component: () => import("/vercel/path0/apps/admin/pages/auth/password/reset/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-password-reset-submit-uuid",
    path: "/auth/password/reset/submit/:uuid()",
    component: () => import("/vercel/path0/apps/admin/pages/auth/password/reset/submit/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "auth-verify-otpId",
    path: "/auth/verify-:otpId()",
    component: () => import("/vercel/path0/apps/admin/pages/auth/verify-[otpId].vue").then(m => m.default || m)
  },
  {
    name: dashboardbE7pCL9f6LMeta?.name,
    path: "/dashboard",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-admins",
    path: "admins",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/admins/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-affiliates",
    path: "affiliates",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/affiliates/index.vue").then(m => m.default || m)
  },
  {
    name: analytics7Q0mcgQG2KMeta?.name,
    path: "crm/analytics",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/crm/analytics.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-crm-analytics-assignedcount",
    path: "assignedcount",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/crm/analytics/assignedcount.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-crm-analytics-assignedtimes",
    path: "assignedtimes",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/crm/analytics/assignedtimes.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-crm-analytics-closingtimes",
    path: "closingtimes",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/crm/analytics/closingtimes.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-crm-analytics",
    path: "",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/crm/analytics/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-crm-analytics-lostcount",
    path: "lostcount",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/crm/analytics/lostcount.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-crm-analytics-woncount",
    path: "woncount",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/crm/analytics/woncount.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-crm",
    path: "crm",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/crm/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-crm-manage",
    path: "crm/manage",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/crm/manage.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-crm-manage-import",
    path: "import",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/crm/manage/import.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-crm-manage-industries",
    path: "industries",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/crm/manage/industries.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-crm-manage-locations",
    path: "locations",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/crm/manage/locations.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-crm-manage-sources",
    path: "sources",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/crm/manage/sources.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-crm-manage-tickets",
    path: "tickets",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/crm/manage/tickets.vue").then(m => m.default || m)
  }
]
  },
  {
    name: myleads48DnWNfp2rMeta?.name,
    path: "crm/myleads",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/crm/myleads.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-crm-myleads-claim",
    path: "claim",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/crm/myleads/claim.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-crm-myleads",
    path: "",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/crm/myleads/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-crm-myleads-salesflow",
    path: "salesflow",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/crm/myleads/salesflow.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-financial",
    path: "financial",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/financial/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "",
    meta: index8B1yqUqPilMeta || {},
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-orders-orderId",
    path: "orders/:orderId()",
    meta: _91orderId_939E65HCjyaWMeta || {},
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/orders/[orderId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-orders",
    path: "orders",
    meta: indexhWEYgp7GcUMeta || {},
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-addons-add",
    path: "organizations/:orgId()/addons/add",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/organizations/[orgId]/addons/add.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-addons-remove",
    path: "organizations/:orgId()/addons/remove",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/organizations/[orgId]/addons/remove.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId",
    path: "organizations/:orgId()",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/organizations/[orgId]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-subscription-add",
    path: "organizations/:orgId()/subscription/add",
    meta: addk0j72PbnCqMeta || {},
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/organizations/[orgId]/subscription/add.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations",
    path: "organizations",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-syncaccess",
    path: "organizations/syncaccess",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/organizations/syncaccess.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-plans",
    path: "plans",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/plans/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-print-order-id",
    path: "print/order/:id()",
    meta: _91id_93671RhuD0xaMeta || {},
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/print/order/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-promotions",
    path: "promotions",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/promotions/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-subscription-renewals",
    path: "subscription-renewals",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/subscription-renewals/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-users-userId",
    path: "users/:userId()",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-users",
    path: "users",
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/users/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-zatca",
    path: "zatca",
    meta: index9a5tgJ4G0hMeta || {},
    component: () => import("/vercel/path0/apps/admin/pages/dashboard/zatca/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/apps/admin/pages/index.vue").then(m => m.default || m)
  }
]